import React from 'react'
import Img from 'gatsby-image'

export default ({ data }) => {
   if (!data) {
     return null
   }
   return (
     <div className="hero">
       <img className="hero-image" src={data.imagen.file.url}/>
       <div className="bg-hero-image" style={{
         backgroundImage: `url(${data.imagen.file.url})`
       }}>
       </div>
         <div className="hero-details">
         <h1 className="hero-title">{data.titulo}</h1>
         <p className="hero-subtitle">{data.subtitulo}</p>
       </div>
      </div>
   )
}

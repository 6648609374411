import React from 'react'
import GalleryGrid from 'react-grid-gallery';

export default ({ data }) => {
  if (!data) {
    return null
  }
  const images = data.imagenes.map((image, index) => {
    return (
      {
        src: image.file.url,
        thumbnail: image.fixed.src,
        thumbnailWidth: 450,
        thumbnailHeight: 250
      }
    )
  })

  return (
    <div className="gallery">
      <h2 className="gallery-title section-headline centered-container">{data.titulo}</h2>
      <GalleryGrid
          images={images}
          enableImageSelection={false}
          rowHeight={300}
          margin={0}
          showLightboxThumbnails={true}
          imageCountSeparator=" de " />
    </div>
  )
}

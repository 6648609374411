import React from 'react'
import {FaWhatsapp, FaPhone, FaRegEnvelope} from 'react-icons/fa';

export default ({ toggleModal }) => (
  <div className="modal-overlay">
    <div className="modal-content" id="free-class">
      <button className="close-modal" aria-label="Close modal" onClick={() => toggleModal()}>
        <span aria-hidden="true">&times;</span>
      </button>
      <h1>Prueba una clase gratis</h1>
      <p>¿Te apetece probar R1 Box? La primera sesión de entrenamiento es gratuita.</p>
      <p>Ven a conocer el centro o a probar alguna de nuestras actividades, da igual el nivel que tengas.</p>
      <p>Contacta con nosotros para concertar tu cita, no te arrepentirás.</p>
      <ul className="modal-contact contact-phones reset-list">
        <li>
          <FaWhatsapp className="fa whatsapp" /> <a href="https://wa.me/34689946939" target="_blank">689 94 69 39</a>
        </li>
        <li>
          <FaPhone className="fa phone" /> <a href="tel:+34976979346" target="_blank">976 979 346</a>
        </li>
        <li>
          <FaRegEnvelope className="fa mail" /> <a href="mailto:hola@r1box.com" target="_blank">hola@r1box.com</a>
        </li>
      </ul>
    </div>
  </div>
)

import React from 'react'
import Header from './header'
import Footer from './footer'
import FreeClassModal from './free-class-modal'
import { ModalProvider } from '../contexts/modal'
import { Helmet } from "react-helmet"

import './../styles/styles.scss'

class Template extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show_free_class_modal: false,
      rwd_open: false
    }

    this.state = {
      show_free_class_modal: false,
      toggleModal: () => {
        this.setState(prevState => ({
          show_free_class_modal: !prevState.show_free_class_modal
        }))
      }
    }

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal () {
    this.setState(prevState => ({
    rwd_open: !prevState.rwd_open
  }))
}

  render() {
    const { children } = this.props
    const rwd_class = this.state.rwd_open ? 'rwd-open' : 'rwd-close'

    return (
     <div className={`page-container ${rwd_class}`}>
       {this.state.show_free_class_modal && (
           <FreeClassModal toggleModal={this.state.toggleModal} />
         )
       }
       <Helmet>
         <link href="https://fonts.googleapis.com/css?family=Anton|Roboto+Mono" rel="stylesheet"/>
       </Helmet>
       <ModalProvider value={this.state}>
         <Header toggleRwd={this.toggleModal}/>
         {children}
         <Footer />
       </ModalProvider>
     </div>
    )
  }
}

export default Template

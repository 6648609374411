import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

export default ({ area }) => (
  <div className="area-teaser">
    <Link to={`/${area.slug}/`}>
      <h2>{area.titulo}</h2>
      <div className="area-image">
        <img alt={area.imagen_preview.title} src={area.imagen_preview.file.url} />
      </div>
    </Link>
  </div>
)

import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

export default ({ article }) => (
  <div className="article-teaser">
    <Link to={`/news/${article.slug}/`}>
      <img alt="" src={article.imagen_preview.file.url} />
    </Link>
    <h3>
      <Link to={`/news/${article.slug}/`}>{article.titulo}</Link>
    </h3>
    <small>{article.createdAt}</small>
    {article.texto_preview &&
      <p className="preview-text">{ article.texto_preview.texto_preview }</p>
    }
    <Link to={`/news/${article.slug}/`} className="view-more">Leer más</Link>
  </div>
)

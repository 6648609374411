import React from 'react'
import {graphql, Link} from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import AreaPreview from '../components/area-preview'
import Hero from '../components/hero'
import Gallery from '../components/gallery'
import {documentToReactComponents} from "@contentful/rich-text-react-renderer"
import {FaPhone, FaRegEnvelope, FaWhatsapp} from "react-icons/fa";

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulNoticia.edges')
    const homepageContent = get(this, 'props.data.contentfulHomepage')
    const metatags = get(this, 'props.data.contentfulHomepage.metatags')

    function mapIframe() {
      return {
        __html: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1490.8169391138042!2d-0.8670415417793244!3d41.64204459925477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd591455c45fdad3%3A0xad54c07e9247d8fa!2sCalle+Belchite%2C+26%2C+50013+Zaragoza!5e0!3m2!1ses!2ses!4v1555323615595!5m2!1ses!2ses" width="100%" height="400" frameBorder="0" style="border:0" allowFullScreen=""></iframe>'
      }
    }

    function videoIframe() {
      return {
      __html: '<iframe width="560" height="315" src="https://www.youtube.com/embed/kackemZPmwA" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>'
      }
    }

    return (
      <Layout>
        <Helmet>
          <title>{metatags ? metatags.meta_title || homepageContent.titulo : homepageContent.titulo } | {siteTitle}</title>
          {metatags && metatags.meta_description &&
            <meta name="description" content={metatags.meta_description.meta_description} />
          }
        </Helmet>
        <Hero data={homepageContent.hero} />
        <div className="news">
          <h2 className="section-headline centered-container">Últimas noticias</h2>
          <ul className="article-list row reset-list">
            {posts.map(({ node }) => {
              return (
                <li className="columns small-12 medium-6 large-4" key={node.slug}>
                  <ArticlePreview article={node} />
                </li>
              )
            })}
          </ul>

          <div className="centered-container">
            <Link to={`/news/`} className="btn-primary view-all">Todas las noticias</Link>
          </div>
        </div>

        <div className="areas">
          <h2 className="section-headline centered-container">Nuestras Áreas</h2>
          <ul className="area-list reset-list row">
            {homepageContent.areas.map(( area ) => {
              return (
                <li className="columns small-12 medium-6" key={area.slug}>
                  <AreaPreview area={area} />
                </li>
              )
            })}
          </ul>
        </div>

        <div className="r1-presentation row">
          <div className="description columns small-12 medium-8 medium-offset-2">
            { documentToReactComponents(homepageContent.texto.json) }
            <div className="r1-video">
              <div dangerouslySetInnerHTML={ videoIframe() } />
            </div>
          </div>
        </div>
        <hr className="half" />

        <Gallery data={homepageContent.galeria} />

        <div id="contact" className="contact-address centered-container">
          <p>
            Encuéntranos en Calle Belchite, 26<br/>
            Zaragoza<br/>
            <ul className="reset-list contact-phones">
              <li>
                <FaWhatsapp className="fa whatsapp" /> <a href="https://wa.me/34689946939" target="_blank">689 94 69 39</a>
              </li>
              <li>
                <FaPhone className="fa phone" /> <a href="tel:+34976979346" target="_blank">976 979 346</a>
              </li>
              <li>
                <FaRegEnvelope className="fa mail" /> <a href="mailto:hola@r1box.com" target="_blank">hola@r1box.com</a>
              </li>
            </ul>
          </p>
        </div>

        <div className="google-map">
          <div dangerouslySetInnerHTML={ mapIframe() } />
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulNoticia(sort: { fields: createdAt, order: DESC }, limit: 3) {
      edges {
        node {
          titulo
          slug
          createdAt(formatString: "DD/MM/YYYY")
          imagen_preview {
            file {
              url
            }
          }
          texto_preview {
            texto_preview
          }
        }
      }
    }
    contentfulHomepage {
      titulo
      hero {
        imagen {
          id
          file {
            url
          }
        }
        titulo
        subtitulo
      }
      areas {
        titulo
        slug
        imagen_preview {
          file {
            url
          }
          title
        }
      }
      galeria {
        titulo
        imagenes {
          file {
            url
          }
          fixed(resizingBehavior: THUMB, width: 450, height: 300, cropFocus: CENTER) {
            src
          }
        }
      }
      texto {
        json
      }
      metatags {
        meta_title
        meta_description {
          meta_description
        }
      }
    }
  }
`

import React from 'react'
import {graphql, Link, StaticQuery} from 'gatsby'
import { ModalConsumer } from '../contexts/modal'

export default ({ toggleRwd }) => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        contentfulHomepage {
          areas {
            titulo
            slug
            metatags {
              copy_menu_principal
            }
          }
        }
        contentfulHorariosYTarifas {
          titulo
          metatags {
            copy_menu_principal
          }
        }
      }
    `}
    render={data => (
      <header className="page-header top-bar">
        <div className="logo-section">
          <Link to="/">
            <img src={"/images/logo-r1.png"} alt="logo R1 Box" className="logo"/>
          </Link>
        </div>
        <div className="group-actions">
          <nav role="navigation">
            <ul className="menu main-navigation">
              {data.contentfulHomepage.areas.map((area, index) =>  (
                <li key={index}>
                  <Link to={`/${area.slug}/`}>{area.metatags ? area.metatags.copy_menu_principal || area.titulo : area.titulo}</Link>
                </li>
                )
              )}
              {data.contentfulHorariosYTarifas && (
                <li>
                  <Link to={`/horarios-tarifas/`}>{
                    data.contentfulHorariosYTarifas.metatags ?
                        data.contentfulHorariosYTarifas.metatags.metatitle || data.contentfulHorariosYTarifas.titulo :
                        data.contentfulHorariosYTarifas.titulo
                  }
                  </Link>
                </li>
              )}
              <li>
                <Link to="/#contact">Contacto</Link>
              </li>
            </ul>
          </nav>

          <ModalConsumer>
            {({ toggleModal }) => (
                <button className="btn-primary free-class" onClick={() => toggleModal()}>Prueba Gratis</button>
            )}
          </ModalConsumer>
        </div>

        <a href="#" className="menu-button" onClick={() => toggleRwd()}>
          <span className="burger-icon"></span>
        </a>
      </header>
    )}
  />
)

import React from 'react'
import {graphql, Link, StaticQuery} from 'gatsby'

export default () => (
    <StaticQuery
      query={graphql`
      query FooterQuery {
        contentfulHomepage {
          areas {
            titulo
            slug
            metatags {
              copy_menu_seo
            }
          }
        }
      }
    `}
      render={data => (
        <footer className="page-footer">
          <div className="row">
            <nav className="navigation columns small-12 medium-8">
              <ul className="footer-navigation">
                {data.contentfulHomepage.areas.map((area, index) => (
                  <li key={index}>
                    <Link to={`/${area.slug}/`}>{area.metatags ? area.metatags.copy_menu_seo || area.titulo : area.titulo}</Link>
                  </li>
                  )
                )}
              </ul>
            </nav>
            <div className="footer-logos columns small-12 medium-4 align-right">
              <img src={"/images/logo-r1-inverted.png"} alt="logo R1 Box" className="logo"/>
            </div>
          </div>
        </footer>
      )}
    />
)
